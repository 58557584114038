define("ember-template-compiler/lib/plugins/assert-input-helper-without-block", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = errorOnInputWithContent;

  function errorOnInputWithContent(env) {
    var moduleName = env.meta.moduleName;
    return {
      name: 'assert-input-helper-without-block',
      visitor: {
        BlockStatement: function BlockStatement(node) {
          if (node.path.original !== 'input') {
            return;
          }

          (true && !(false) && (0, _debug.assert)(assertMessage(moduleName, node)));
        }
      }
    };
  }

  function assertMessage(moduleName, node) {
    var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
    return "The {{input}} helper cannot be used in block form. " + sourceInformation;
  }
});